var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Nuova Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(" Nuova Email ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MinusIcon"},on:{"click":function($event){return _vm.$emit('update:shall-show-email-compose-modal', false)}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":_vm.discardEmail}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Invia ")])],1)]},proxy:true}])},[_c('b-form',[_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v("A: ")]),_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-to"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.composeData.to),callback:function ($$v) {_vm.$set(_vm.composeData, "to", $$v)},expression:"composeData.to"}}),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.showCcField=!_vm.showCcField}}},[_vm._v("Cc")]),_c('span',{staticClass:"ml-1 cursor-pointer",on:{"click":function($event){_vm.showBccField=!_vm.showBccField}}},[_vm._v("Bcc")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCcField),expression:"showCcField"}],staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-cc"}},[_vm._v("CC: ")]),_c('v-select',{staticClass:"flex-grow-1 email-cc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-cc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.composeData.cc),callback:function ($$v) {_vm.$set(_vm.composeData, "cc", $$v)},expression:"composeData.cc"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBccField),expression:"showBccField"}],staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-bcc"}},[_vm._v("Bcc ")]),_c('v-select',{staticClass:"flex-grow-1 email-bcc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-bcc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.composeData.bcc),callback:function ($$v) {_vm.$set(_vm.composeData, "bcc", $$v)},expression:"composeData.bcc"}})],1),_c('div',{staticClass:"compose-mail-form-field"},[_c('label',{attrs:{"for":"email-subject"}},[_vm._v("Oggetto: ")]),_c('b-form-input',{attrs:{"id":"email-subject"},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}})],1),_c('div',{staticClass:"message-editor"},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),_c('div',{staticClass:"d-flex border-bottom-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }