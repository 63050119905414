<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')"
            >
              Nuova Email
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                <!--
                <b-badge
                  v-if="emailsMeta[filter.title]"
                  pill
                  :variant="resolveFilterBadgeColor(filter.title)"
                  class="float-right"
                >
                  {{ emailsMeta[filter.title] }}
                </b-badge>
                -->
              </b-list-group-item>
            </b-list-group>

            <div v-if="messaggio">
              <hr />
              <div class="px-2">{{ messaggio }}</div>
            </div>

            <!--
            <hr />
            <p>Debug</p>
            <hr />
            <pre>{{ emailsMeta }}</pre>
            -->

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
    messaggio: '',
  },
  setup() {
    //console.log("dentro EmailLeftSidebar.vue - Speriamo bene ...")

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const emailFilters = [
      { title: 'IN ARRIVO', icon: 'MailIcon', route: { name: 'google-workspace-gmail-index' } },
      { title: 'SPECIALI', icon: 'StarIcon', route: { name: 'google-workspace-gmail-folder', params: { folder: 'starred' } } },
      { title: 'BOZZE', icon: 'Edit2Icon', route: { name: 'google-workspace-gmail-folder', params: { folder: 'draft' } } },
      { title: 'INVIATA', icon: 'SendIcon', route: { name: 'google-workspace-gmail-folder', params: { folder: 'sent' } } },
      { title: 'SPAM', icon: 'InfoIcon', route: { name: 'google-workspace-gmail-folder', params: { folder: 'spam' } } },
      { title: 'CESTINO', icon: 'TrashIcon', route: { name: 'google-workspace-gmail-folder', params: { folder: 'trash' } } },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'BOZZE') return 'light-warning'
      if (filter === 'SPAM') return 'light-danger'
      return 'light-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailFilters,
    }
  },
}
</script>

<style>

</style>
