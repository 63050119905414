<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header ">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center w-75">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.subject }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <!-- Mark Starred -->
        <feather-icon
          icon="StarIcon"
          size="17"
          class="cursor-pointer"
          :class="{ 'text-warning fill-current': emailViewData.isStarred }"
          @click="$emit('toggle-email-starred')"
        />

        <!-- Move email to folder -->
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
          class="ml-75"
        >
          <template #button-content>
            <feather-icon
              icon="FolderIcon"
              size="17"
            />
          </template>

          <b-dropdown-item @click="$emit('move-email-to-folder', 'draft')">
            <feather-icon icon="Edit2Icon" />
            <span class="align-middle ml-50">In Bozza</span>
          </b-dropdown-item>

          <b-dropdown-item @click="$emit('move-email-to-folder', 'spam')">
            <feather-icon icon="InfoIcon" />
            <span class="align-middle ml-50">In Spam</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-show="$route.params.folder !== 'trash'"
            @click="$emit('move-email-to-folder', 'trash')"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Nel Cestino</span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- Mark Un-Read -->
        <feather-icon
          icon="MailIcon"
          class="ml-75 cursor-pointer"
          size="17"
          @click="$emit('mark-email-unread')"
        />

        <!-- Move to Trash -->
        <feather-icon
          v-show="$route.params.folder !== 'trash'"
          icon="TrashIcon"
          class="ml-75"
          size="17"
          @click="$emit('move-email-to-folder', 'trash')"
        />

      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Email data -->
      <b-row class="pt-3">
        <b-col cols="12">
          <email-message-card :message="emailViewData" />
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                <b-link>Rispondi</b-link>
                o
                <b-link>Inoltra</b-link>
                questa email
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmailMessageCard from './EmailMessageCard.vue'

export default {
  components: {

    // BSV
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    EmailMessageCard,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      // UI
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
